import scrollTo from "gatsby-plugin-smoothscroll"

const smoothScroll = e => {
  e.preventDefault()

  const el = e.target
  const hash = el.href && el.href.split("#") && el.href.split("#")[1]

  hash && scrollTo(`#${hash}`)
}

export default smoothScroll
