import React, { useContext, useEffect } from "react"
import { ArrowDown, ArrowRight, Check } from "react-feather"
import HelmetHead from "../../components/HelmetHead"
import Curve1 from "../../svgs/shapes/curves/curve-1.svg"
import BackgroundSection from "../../components/BackgroundSection"
import smoothScroll from "../../components/smoothScroll"
import LayoutContext from "../../components/LayoutContext"

const Karriere = () => {
  const [layout, setLayout] = useContext(LayoutContext)

  useEffect(() => {
    setLayout(state => ({
      ...state,
      header: { color: "dark" },
    }))
  }, [])
  return (
    <>
      <HelmetHead
        title="Karriere bei der Maklerzentrale Deutschland"
        description="Heute an morgen denken. Starte deine Karriere bei der Maklerzentrale. Wir helfen Eigentümern und Verkäufern dabei, den passenden Makler zu finden."
      />
      <BackgroundSection
        src="pexels-photo-927022.jpg"
        className="bg-cover"
        overlayColor={`linear-gradient(rgba(0,0,0,.1), rgba(0,0,0,.1))`}
      >
        <div className="container">
          <div
            className="row justify-content-center align-items-end pb-10"
            style={{ minHeight: "90vh" }}
          >
            <div className="col-12 col-md-10 col-lg-8 text-center">
              <h1 className="display-2 fw-bold text-white text-shadow-custom">
                Heute an morgen denken
              </h1>

              <p className="lead text-white text-shadow-custom mb-0">
                Starte deine Karriere bei der Maklerzentrale.
              </p>
            </div>
          </div>
        </div>
      </BackgroundSection>

      <div className="position-relative">
        <div className="shape shape-bottom shape-fluid-x svg-shim text-light">
          <Curve1 />
        </div>
      </div>

      <section className="py-7 border-bottom border-gray-300">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <div className="scroll-beacon" id="bewerbung-info" />
              <a
                href="#bewerbung-info"
                className="btn btn-white btn-rounded-circle shadow mt-n11"
                onClick={smoothScroll}
              >
                <ArrowDown size={20} />
              </a>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-6 text-center border-none-sm-down border-end border-gray-300">
              <h6 className="text-uppercase text-secondary mb-1">Telefon</h6>
              <div className="mb-5 mb-sm-0">
                <a href="tel:+498002242020" className="h4">
                  0800 2242020
                </a>
              </div>
            </div>
            <div className="col-12 col-sm-6 text-center">
              <h6 className="text-uppercase text-secondary mb-1">E-Mail</h6>
              <a href="mailto:bewerbung@maklerzentrale.com" className="h4">
                bewerbung@maklerzentrale.com
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="pt-8 pt-md-11">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-7 col-lg-8">
              <h2>Maklerzentrale</h2>

              <p className="text-muted mb-6 mb-md-8">
                Die Maklerzentrale hilft Eigentümern und Verkäufern dabei, den
                passenden Makler zu finden. Wir beraten den Kunden unabhängig zu
                seinem Vorteil. Unser Schwerpunkt ist die Vermittlung von
                passenden Maklern, die sich durch bestimmte Eigenschaften und
                besondere Leistungen hervorheben.
              </p>

              <h3>Deine Aufgaben</h3>

              <p className="text-muted mb-5">
                Du begleitest Menschen bei emotional und finanziell wichtigen
                Entscheidungen und erreichst zielorientiert und eigenständig das
                für den Kunden beste Ergebnis.
              </p>

              <div className="d-flex">
                <div className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                  <Check size={15} />
                </div>

                <p className="text-muted">
                  Immobilienanalyse mit allen Eigenschaften sowie Vor- und
                  Nachteilen
                </p>
              </div>
              <div className="d-flex">
                <div className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                  <Check size={15} />
                </div>

                <p className="text-muted">
                  Immobilienbewertung unter Berücksichtigung der aktuellen
                  Marktchancen
                </p>
              </div>
              <div className="d-flex">
                <div className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                  <Check size={15} />
                </div>

                <p className="text-muted">
                  Gründliche Recherche hervorragender und passender Makler vor
                  Ort
                </p>
              </div>
              <div className="d-flex mb-6 mb-md-8">
                <div className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                  <Check size={15} />
                </div>

                <p className="text-muted">
                  Vermittlungsdienstleistung an die Eigentümer
                </p>
              </div>

              <h3 className="mb-5">Deine Skills</h3>

              <div className="d-flex">
                <div className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                  <Check size={15} />
                </div>
                <p className="text-muted">
                  Solides Immobilienfachwissen hast Du durch Ausbildung oder
                  Berufserfahrung
                </p>
              </div>
              <div className="d-flex">
                <div className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                  <Check size={15} />
                </div>
                <p className="text-muted">
                  Durch Empathie und Fachkenntnisse gewinnst Du Anerkennung
                </p>
              </div>
              <div className="d-flex mb-6 mb-md-8">
                <div className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                  <Check size={15} />
                </div>
                <p className="text-muted">
                  Ziele erreichst Du durch Engagement und Professionalität
                </p>
              </div>

              <h3 className="mb-5">Deine Persönlichkeit</h3>

              <div className="d-flex">
                <div className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                  <Check size={15} />
                </div>
                <p className="text-muted">
                  Ein hohes Maß an Eigenverantwortung
                </p>
              </div>
              <div className="d-flex">
                <div className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                  <Check size={15} />
                </div>
                <p className="text-muted">
                  Du arbeitest gerne in einem teamorientierten Umfeld
                </p>
              </div>
              <div className="d-flex">
                <div className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                  <Check size={15} />
                </div>
                <p className="text-muted">
                  Du bildest dich gerne weiter und hast klare
                  Karriereperspektiven
                </p>
              </div>
              <div className="d-flex mb-6 mb-md-8">
                <div className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                  <Check size={15} />
                </div>
                <p className="text-muted">
                  Du arbeitest gerne in einer Festanstellung in Vollzeit mit
                  attraktivem Festgehalt
                </p>
              </div>

              <h2 className="mb-5">Wir haben dein Interesse geweckt?</h2>
              <p className="text-muted mb-5">
                Leg jetzt los und sende uns deine aussagekräftige Bewerbung.
              </p>
              <a
                href="mailto:bewerbung@maklerzentrale.com"
                className="btn btn-block btn-primary d-flex align-items-center lift mb-8"
                style={{ maxWidth: "240px" }}
              >
                Bewerbung via E-Mail
                <ArrowRight className="ms-2" />
              </a>
            </div>
            <div className="col-12 col-md-5 col-lg-4">
              <div className="card shadow-light-lg mb-5">
                <div className="card-body">
                  <blockquote className="blockquote mb-0 text-center">
                    <p className="mb-5 mb-md-7">
                      “Der Wert eines Unternehmens wird nicht durch das Kapital
                      bestimmt, sondern durch den Spirit der in ihm herrscht.”
                    </p>

                    <footer className="blockquote-footer">
                      <span className="h6 text-uppercase">- Oscar Karem -</span>
                    </footer>
                  </blockquote>
                </div>
              </div>
              <div className="card shadow-light-lg">
                <div className="card-body">
                  <h4>Du hast Fragen?</h4>
                  <p className="fs-sm text-muted mb-5">
                    Ruf uns an oder schreib uns eine E-Mail.
                  </p>
                  <h6 className="fw-bold text-uppercase text-gray-700 mb-2">
                    Telefon
                  </h6>
                  <p className="fs-sm mb-5">
                    <a href="tel:+498002242020" className="text-reset">
                      0800 2242020
                    </a>
                  </p>
                  <h6 className="fw-bold text-uppercase text-gray-700 mb-2">
                    E-Mail
                  </h6>
                  <p className="fs-sm mb-0 me-n2">
                    <a
                      href="mailto:bewerbung@maklerzentrale.com"
                      className="text-reset"
                    >
                      bewerbung@makler&shy;zentrale.com
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <hr className="my-6 border-gray-300" />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Karriere
